input, textarea {
  width: calc(100% - 18px);
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #1c87c9;
  outline: none;
  direction: center;
}
input::placeholder {
  color: #666;
}
.main-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #1c87c9;
}
form {
  padding: 25px;
  margin: 25px;
  box-shadow: 0 2px 5px #f5f5f5; 
  background: #f5f5f5; 
}
button {
  width: 100%;
  padding: 10px;
  border: none;
  background: #1c87c9; 
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
button:hover {
  background: #2371a0;
}    
.error {
  background-color: #FAF0F0;
}
.g-recaptcha {
  width: 100%;
}